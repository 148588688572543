//@author: kate

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BpeVarsComponent } from "./bpe.vars.component";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25TableModule } from "../s25-table/s25.table.module";
import { S25DndSortableModule } from "../s25-dnd-sortable/s25.dnd.sortable.module";
import { S25InfoMessageModule } from "../s25-info-message/s25.info.message.module";
import { PopoverModule } from "../s25-popover/popover.module";
import { S25HelpDirective } from "../s25-help/s25.help.directive";
import { S25TableBuilderComponent } from "./s25.table.builder.component";

@NgModule({
    declarations: [BpeVarsComponent, S25TableBuilderComponent],
    imports: [
        CommonModule,
        FormsModule,
        S25DropdownPaginatedModule,
        S25MultiselectModule,
        S25CheckboxModule,
        S25TableModule,
        S25DndSortableModule,
        S25InfoMessageModule,
        PopoverModule,
        S25HelpDirective,
    ],
    providers: [BpeVarsComponent],
    exports: [BpeVarsComponent, S25TableBuilderComponent],
})
export class BpeModule {
    constructor() {}
}
